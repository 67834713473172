.main{
    display: flex;
    margin: auto;
    align-items: center;
    height: 80vh;
    width: 70vw;
    margin-top: 10vh;
    border-radius: 10px;
    background-color: rgb(29, 23, 23);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    /* background: rgb(138,4,132); */
/* background: linear-gradient(90deg, rgba(138,4,132,1) 16%, rgba(25,7,99,1) 62%, rgba(212,7,18,1) 100%, rgba(5,84,15,1) 100%); */

}

.container-1{
    width: 50%;
    /* background-color: black; */
    display: flex;
    height: 100%
    /* padding: 10rem; */
}

.image{
    width: 100%;
}

.container-2{
    width: 40%;
    height: 100%;
    margin-top: 10%;
    /* background-color: rgb(173, 8, 148); */
    align-items: center;
    /* padding: 10rem; */
}

.btn-submit{
    padding: 10px 0;
}

.btn{
    width: 100%;
    /* height: 10vh; */
    background-color: yellow;
    margin-top: 10%;
    border: none;
}

.btn-submit:hover{
    opacity:0.5;
    scale: 0.9;
}

.form{
    padding: 3rem;
    /* background: rgba(20, 5, 51, 0.71); */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.2px);
    -webkit-backdrop-filter: blur(8.2px);
}


.input{
    color: white;
    margin-top: 1.5rem;
}

.input label{
    font-size: larger;
    font-weight: bolder;
}

.input-box{
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    margin-top: 1rem;
    color:white;
}

@media only screen and (max-width:"1024px"){
    .form{
        padding: 1rem;
        background: rgba(20, 5, 51, 0.71);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(8.2px);
        -webkit-backdrop-filter: blur(8.2px);
        border: 1px solid rgba(255, 255, 255, 0.41);
    
    }
}


@media only screen and (max-width:512px){
    .main{
        display: flex;
        flex-direction: column-reverse;
        margin: auto;
        align-items: center;
        height: 100vh;
        width: 100vw;
        /* margin-top: 10vh; */
        border-radius: 10px;
        background-color: rgb(29, 23, 23);
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        /* background: rgb(138,4,132); */
    /* background: linear-gradient(90deg, rgba(138,4,132,1) 16%, rgba(25,7,99,1) 62%, rgba(212,7,18,1) 100%, rgba(5,84,15,1) 100%); */
    
    }


    .container-1{
        width: 100%;
        display: flex;
        height: 50%;
        margin: 0;
        /* padding: 10rem; */
    }

    .container-2{
        width: 100%;
        height: 50%;
        /* margin-top: 10%; */
        /* background-color: rgb(173, 8, 148); */
        /* align-items: center; */
        /* padding: 10rem; */
    }

    .form{
        /* padding: 3rem; */
        /* background: rgba(20, 5, 51, 0.71); */
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(8.2px);
        -webkit-backdrop-filter: blur(8.2px);
    }
    
}


@media only screen and (max-width:780px){
    .main{
        display: flex;
        margin: auto;
        align-items: center;
        height: 100vh;
        width: 100vw;
        /* margin-top: 10vh; */
        border-radius: 10px;
        background-color: rgb(29, 23, 23);
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        /* background: rgb(138,4,132); */
    /* background: linear-gradient(90deg, rgba(138,4,132,1) 16%, rgba(25,7,99,1) 62%, rgba(212,7,18,1) 100%, rgba(5,84,15,1) 100%); */
    
    }

    .container-1{
        width: 100%;
        display: flex;
        height: 50%;
        margin: 0;
        /* padding: 10rem; */
    }

    .container-2{
        width: 100%;
        height: 50%;
        margin-top: 10%;
        /* background-color: rgb(173, 8, 148); */
        /* align-items: center; */
        /* padding: 10rem; */
    }
} 

