

.container{
    margin-top: 5%;
    border-radius: 10%;
}


.random{
    margin-top: 2vh;
    width: 100%;
    height: 70vh;
    border-radius: 20px;
    opacity: 0.6;
    z-index: 100;
}


.randomShort{
    position: absolute;
    bottom:5rem;
    right: 2.5rem;
    height: 20vh;
    width:20%;
    border-radius: 20px;
    border: 2px solid grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.randomShort:hover{
    opacity:0.7;
    scale: 0.9;
    cursor: pointer;
}

.carouselImage{
    border-radius: 20px;
    width:20vw;
    height:35vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.carouselImage:hover{
    opacity: 0.5;
    scale:0.9
}

.playBtnAnimation{
    position: absolute;
    top:30%;
    left:30%;
}


.carousel{
    position: relative;
}


.btn{
    opacity: 0;
    display: none;
    transform: translateY(0);
    transition: all 1s;
    border: none;
    outline: none;
    border-radius: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: rgba(208, 208, 10, 0.97);
}


/* .carousel:is(:hover) .btn{
    display: block;
    opacity: 1;
    transition: all 1s;
    transform: translateY(-130px);

} */

.items{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.card{
    margin-inline: 10px;
    margin: 10px;
}

/* .play{
    transition: all 1s;
} */

.play{
    transition: all 1s;
}

.play:hover{
    scale: 0.8;
}

.cardImage{
    border-radius: 20px;
     height: 40vh;
     width: 20vw;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.cardImage:hover{
    opacity:0.4;

}


/* .play:is(:hover) .btn{
    display: block;
    opacity: 1;
    transition: all 1s;
    transform: translateY(-130px);

} */

.play:is(:hover) .btn{
    display: block;
    opacity: 1;
    transition: all 1s;
    transform: translateY(-130px);
    /* width:100%; */

}

.background{
    background-color: #df1e1e;
}

.play:hover{
    scale: 0.8;
}

.loadingAnimation{
    position: absolute;
    top:-40vh;
    padding: 0;
    background-color: black;
}



/* .navbar{
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    box-shadow: rgba(11, 23, 161, 0.25) 0px 54px 55px, rgba(16, 20, 233, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
} */


@media only screen and (max-width:992px){
    .random{
        margin-top: 10vh;
    }
    .randomShort{
        position: absolute;
        bottom:7rem;
        right: 2.5rem;
        height: 20vh;
        width:12rem;
        border-radius: 20px;
        border: 2px solid grey;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .loadingAnimation{
        position: absolute;
        top:-30vh;
        padding: 0;
        background-color: black;
    }
}


@media only screen and (max-width:768px){
    
    .randomShort{
        position: absolute;
        bottom:6rem;
        right: 2.5rem;
        height: 20vh;
        width:12rem;
        border-radius: 20px;
        border: 2px solid grey;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cardImage{
        border-radius: 20px;
         height: 40vh;
         width: 30vw;
    
    }
    .loadingAnimation{
        position: absolute;
        top:-20vh;
        padding: 0;
        
        background-color: black;
    }
}

@media only screen and (max-width:600px){

    .random{
        margin-top: 10vh;
    }

    .randomShort{
        position: absolute;
        bottom:4rem;
        right: 2.5rem;
        height: 20vh;
        width:12rem;
        border-radius: 20px;
        border: 2px solid grey;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .cardImage{
        border-radius: 20px;
         height: 40vh;
         width: 80vw;
    
    }
    .loadingAnimation{
        position: absolute;
        top:-10vh;
        padding: 0;
        
        background-color: black;
    }
}




